@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  transition-property: opacity,transform,background,color,box-shadow,height,padding,letter-spacing,text-decoration!important;
}

html {
  @apply scroll-smooth duration-200
}

body {
  font-family: 'Rubik', sans-serif;
  padding-right: 0!important;
  overflow: overlay;
}

b {
  @apply font-semibold
}

.dark {
  @apply bg-bg-dark duration-200
}

a {
  @apply cursor-pointer
}

#app {
  @apply bg-slate-200 dark:bg-gray-900 font-sans text-center
  
}

.rtl {
  direction: rtl;
}

.lrt {
  direction: ltr;
}